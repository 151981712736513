import { default as _91slug_93XVfADxcDunMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexXFVo730oosMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesB0TudW22i9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93AFPyQKYSq7Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexuZabeZUzRfMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackjloYIhpw8RMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usN49zG0x2tHMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackbsyROBevRnMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeEEidl4wUr6Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveK5yAtINlG7Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as index5EH8pt8JOhMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_938y1OB8T2yJMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsf2X8CgH0XxMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsDrbDXzaMIZMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexMPtvQVRErFMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index5xHQnbFbwLMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeSPfi1mZKwEMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsRd1aafegHhMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usS168sM4ZJCMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93CBpGqyPMeoMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexsPJbTfgWAlMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93f85g4XUlP9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93Z7max8KzaBMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsoIGPO18k1wMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexUl7ufQ1z0pMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as indexvrVOr4i059Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as index4kBTrqdg7oMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexdbD9ZWNNXNMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexuml0j2XdIAMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsLilNCNgQqHMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completionxrYcKlbotVMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as detailsrFr2fwcV0uMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93pMop8WUbNKMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93NF57EqQmsrMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93azKVWKSyEIMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93nJ1aaONiXYMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93aYBHaFgAtJMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsJVINxkECd5Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebateCPfAly5KMCMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as indexgAyclq09b3Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93fGWbymgOKQMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details5B09cR9kbsMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youxdDPmQuIo6Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93LmBm8qHP81Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexTizRZbNyt9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93G8ys5I5V3GMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as index7foINEYPuqMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordfneWmUwfcUMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93M68g5Z0NelMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expired9k9OEAv2pVMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexuN0QWR6iiFMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexEtVIRsI5OvMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errormXQhYZmfy0Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexhDTvWJFA5cMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bXhajodH2nMMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chat5tmdgOdXZrMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as index1Cyo1n4QQZMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addsI0t9kuJ6jMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupYwXZjDYwjlMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleCWJ5lnumbwMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexEDPYw9wZn8Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93i8S7LQlf6oMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsOiKgHOa0F9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indextg2bE3TbLuMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youf6c5b8FC3YMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexlKqPN4c2F7Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexy50ONCEMCBMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexGDRHOV6GD9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexZoMXkOEjT4Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93fWMuwE0CrrMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_932XNg3ks5O6Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationFmCRxxj4OSMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsdsMzlfhqeQMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexuBD93oG3WQMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsNypUUHgejiMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youhu1bexzAzlMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93iAfRp0duDdMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexf1AS6aTH4lMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexBLfBcZfRv9Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesO86w4BB6l0Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93zjr2SzCMiBMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsddHNGEAUabMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youkxg7LW8C0eMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexIqnlUtLs86Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmationJn7YUZDgSsMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsXgDnL8fPEpMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexXpV4uEV5YIMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexnAiEUhocaPMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addiErX5sTuWvMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youUYJTnkv4soMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexqL8gdNTffCMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as success81PU6RRdmRMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93g1lQEFnZ5tMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailst7bDkToFE0Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginM7q16mOJtqMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93chTvwR7WEzMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_9382bVTaiNyPMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexzfTY3xmSeRMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsKQj98pPKqQMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexI16Y2MPtEFMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45youWVKHRHOZaUMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93UgfMf4JOcAMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93kadOK5TqTnMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93cTDniSBsr1Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyPM5hDSTm4PMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerxmkIwGmTcGMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordqo4hbf5kq0Meta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useb7PNvfWXYDMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyAl4OaJ5NUoMeta } from "/codebuild/output/src2115555469/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackjloYIhpw8RMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93CBpGqyPMeoMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsoIGPO18k1wMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexUl7ufQ1z0pMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexvrVOr4i059Meta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index4kBTrqdg7oMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexdbD9ZWNNXNMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexuml0j2XdIAMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45detailsLilNCNgQqHMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completionxrYcKlbotVMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsrFr2fwcV0uMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93pMop8WUbNKMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_93NF57EqQmsrMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_93azKVWKSyEIMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93nJ1aaONiXYMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93aYBHaFgAtJMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsJVINxkECd5Meta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebateCPfAly5KMCMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordfneWmUwfcUMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginM7q16mOJtqMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerxmkIwGmTcGMeta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordqo4hbf5kq0Meta || {},
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2115555469/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]