import revive_payload_client_YTGlmQWWT5 from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/codebuild/output/src2115555469/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2115555469/src/packages/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/codebuild/output/src2115555469/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kpnqASteq8 from "/codebuild/output/src2115555469/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_01O9J0dmU6 from "/codebuild/output/src2115555469/src/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_zl2pp1dYXk from "/codebuild/output/src2115555469/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_interceptor_gC4tj30w10 from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/api-interceptor.js";
import landing_page_k63M66wvK9 from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/landing-page.js";
import click_outside_oytHm5ka8v from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/click-outside.js";
import bus_GHaWeQPCWC from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/bus.js";
import index_client_ubwrhz3QyY from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/toast/index.client.js";
import datepicker_client_Aec8sftcls from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/datepicker.client.js";
import apollo_graphql_9MsDxzsGcg from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/apollo-graphql.js";
import appcues_client_PzfMhqVRj0 from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/appcues.client.ts";
import optimizely_Njt9fpJUew from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/optimizely.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/sentry.client.ts";
import tracking_client_fEUopUxxSj from "/codebuild/output/src2115555469/src/packages/nuxt/plugins/tracking.client.js";
import plugin_client_QHzhVBB2Ll from "/codebuild/output/src2115555469/src/packages/nuxt/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_kpnqASteq8,
  plugin_01O9J0dmU6,
  plugin_zl2pp1dYXk,
  api_interceptor_gC4tj30w10,
  landing_page_k63M66wvK9,
  click_outside_oytHm5ka8v,
  bus_GHaWeQPCWC,
  index_client_ubwrhz3QyY,
  datepicker_client_Aec8sftcls,
  apollo_graphql_9MsDxzsGcg,
  appcues_client_PzfMhqVRj0,
  optimizely_Njt9fpJUew,
  sentry_client_shVUlIjFLk,
  tracking_client_fEUopUxxSj,
  plugin_client_QHzhVBB2Ll
]