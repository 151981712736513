import QuotesService from '../../server/service/quotes.service'
import { VERTICAL } from '~/helpers/vertical.constants'
const quoteService = new QuotesService()
const actions = {
  loader (status = true) {
    const layoutStore = useLayoutsStore()
    layoutStore.setPageLoader(status)
  },
  /*
   * Quotes fetching store action
   * @returns {Promise<unknown>}
   */
  async fetchQuotes (payload = {}, loader = true) {
    if (loader) {
      this.loader(true)
    }

    const r = await quoteService.fetchLeads(payload)
    return checkResponse(r, false)
      .then(response => {
        this.quotes = response.data?.map(q => {
          q.source = 'Online comparison'
          return q
        }) || []
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      }).finally(() => {
        if (loader) {
          this.loader(false)
        }
      })
  },
  /*
 * Fetches opportunities and updates the quotes store.
 * This function retrieves opportunities from the quote service and processes the response.
 * It filters and maps the response data to update the quotes store with expert recommendations.
 * For life insurance opportunities, it fetches additional agent quotes and updates the store.
 *
 * @param {Object} payload - The payload for the opportunities request.
 * @param {boolean} [loader=true] - Flag to show or hide the loader.
 * @returns {Promise<void>} A promise that resolves with the response data or rejects with an error.
 */
  async fetchOpportunities (payload = {}, loader = true) {
    if (loader) {
      this.loader(true)
    }
    const r = await quoteService.opportunities(payload)
    return checkResponse(r, false)
      .then(response => {
        this.quotes.push(...(response.data?.filter(q => q.vertical !== VERTICAL.LIFE_INSURANCE).map(q => {
          q.source = 'Expert recommendation'
          return q
        }) || []))
        response.data?.filter(q => q.vertical === VERTICAL.LIFE_INSURANCE).forEach(async q => {
          this.loader(true)
          const uniqueIds = await useFetch('/api/life-insurance/agent-quotes', {
            method: 'get',
            params: {
              opportunity_id: q.opportunity_id
            }
          }).then(res => res?.data?.value?.data?.data || [])
            .finally(() => {
              this.loader(false)
            })
          uniqueIds.forEach(item => {
            this.quotes.push({
              ...q,
              ...item,
              source: 'Broker Quote'
            })
          })
        })
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      }).finally(() => {
        if (loader) {
          this.loader(false)
        }
      })
  },
  /*
 * Applies life insurance quotes.
 * This function sends a request to the `/api/life-insurance/quotes/apply` endpoint
 * with the provided payload and handles the response.
 * It also manages the loader state during the request.
 *
 * @param {Object} payload - The payload for the quotes application request.
 * @param {boolean} [loader=true] - Flag to show or hide the loader.
 * @returns {Promise<Object>} A promise that resolves with the response data or rejects with an error.
 */
  async applyQuotes (payload = {}, loader = true) {
    if (loader) {
      this.loader(true)
    }
    const r = await useFetch('/api/life-insurance/quotes/apply', {
      method: 'get',
      params: payload
    }).then(res => res)
    return checkResponse(r, false)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      }).finally(() => {
        if (loader) {
          this.loader(false)
        }
      })
  },
  async requestCallback (payload = {}, loader = true) {
    if (loader) {
      this.loader(true)
    }
    const r = await useFetch('/api/life-insurance/request-callback', {
      method: 'get',
      params: payload
    }).then(res => res)
    return checkResponse(r, true)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      }).finally(() => {
        if (loader) {
          this.loader(false)
        }
      })
  }
}
export default actions
